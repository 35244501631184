import { getCookie, createCookie } from './util';

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.get('avad')) {
    const existingCookie = getCookie('affiliateCustomer');
    if (!existingCookie) {
        createCookie('affiliateCustomer', 'true', 0, '/');
    }
}
